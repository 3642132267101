<template>
  <v-container id="quality" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card color="tabBackgroundColor">
      <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows @change="onTabChange">
        <v-tab>{{ $t('metaReport.metaPerformance.commercial.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaPerformance.discountPrefix.title') }}</v-tab>
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshCurrentTab">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-tab-item key="1">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-performance-commercial-analytics :loading="loading" :refresh="refresh[0]" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="2">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-performance-discount-prefix-analytics :loading="loading" :refresh="refresh[1]" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import MetaPerformanceCommercialAnalytics from '@/views/components/business/analytics/meta/MetaPerformanceCommercialAnalytics';
  import MetaPerformanceDiscountPrefixAnalytics from '@/views/components/business/analytics/meta/MetaPerformanceDiscountPrefixAnalytics';

  export default {
    name: 'MetaPerformance',
    components: { AlertNotification, MetaPerformanceCommercialAnalytics, MetaPerformanceDiscountPrefixAnalytics },
    data () {
      return {
        alert: '',
        currentTab: 0,
        loading: false,
        refresh: [null, null, null, null, null, null, null, null, null, null, null]
      };
    },
    mounted () {},
    methods: {
      refreshCurrentTab () {
        this.onTabChange(this.currentTab);
      },
      onTabChange (tab) {
        this.loading = true;
        this.refresh[tab] = Date.now();
        this.currentTab = tab;
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>

<template>
  <v-container id="meta-performance-discount-prefix-analytics">
    <advanced-table :title="title" :headers="headers" :endpoint="endpoint" :transform="transform" :loading="loading" :refresh="refresh" @success="success" @fail="fail" />
  </v-container>
</template>

<script>
  import AdvancedTable from '@/views/components/common/AdvancedTable';

  export default {
    name: 'MetaPerformanceDiscountPrefixAnalytics',
    components: { AdvancedTable },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        title: this.$t('metaReport.metaPerformance.discountPrefix.title'),
        headers: [
          {
            text: this.$i18n.t('metaReport.metaPerformance.discountPrefix.column.prefix'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'prefix'
          },
          {
            text: this.$i18n.t('metaReport.metaPerformance.discountPrefix.column.generated'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'generated'
          },
          {
            text: this.$i18n.t('metaReport.metaPerformance.discountPrefix.column.used'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'used'
          },
          {
            text: this.$i18n.t('metaReport.metaPerformance.discountPrefix.column.performance'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'performance'
          }
        ],
        endpoint: 'analytics/meta/performance/discount/prefix',
        transform: {
          performance: (performance) => (Math.round(performance * 10000, 2) / 100) + '%'
        }
      };
    },
    methods: {
      success () {
        this.$emit('success');
      },
      fail (error, message) {
        this.$emit('fail', error, message);
      }
    }
  };
</script>
